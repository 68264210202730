import React from 'react';
import { withPrefix } from 'gatsby';
import { Button, Col, Row } from 'reactstrap';

import Wrapper from '../../components/wrapper';

export default props => {
  const page = props['*'];

  return (
    <Wrapper page={page}>
      <div className='post'>
        <h1 className='d-block d-lg-none page'>Information</h1>
        <Row className='mt-3 mb-5 text-center'>
          <Col sm='6' md='3'>
            <h3>TEX-E</h3>
            <a
              className='text-center'
              target='_blank'
              rel='noopener noreferrer'
              href={withPrefix('/pdf/tex-e.pdf')}
            >
              <Button>Product Brochure</Button>
            </a>
          </Col>
          <Col sm='6' md='3'>
            <h3>Features</h3>
            <a
              className='text-center'
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.furukawa.co.jp/tex-e/en/product/texe_feature.html'
            >
              <Button>More Features</Button>
            </a>
          </Col>
          <Col sm='6' md='3'>
            <h3>TEX-ELZ</h3>
            <a
              className='text-center'
              target='_blank'
              rel='noopener noreferrer'
              href={withPrefix('/pdf/tex-elz.pdf')}
            >
              <Button>Product Brochure</Button>
            </a>
          </Col>
          <Col sm='6' md='3'>
            <h3>Furukawa</h3>
            <a
              className='text-center'
              target='_blank'
              rel='noopener noreferrer'
              href='http://www.tex-e.com/'
            >
              <Button>tex-e.com</Button>
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Triple Insulated Wires “TEX-E Series” have a prominent feature: no
              need of interlayer insulation tape or barrier tape between the
              primary and secondary coils of a transformer. This positive
              feature of TEX-E serves to downsize switching transformers, and
              promises high production efficiency and cost reduction. “TEX-E
              Series Wires” paves the way to the design of the most modern
              switching transformers.
            </p>
            <div className='text-center'>
              <img
                alt='TEX-E'
                className='img-fluid'
                src={withPrefix('/img/tex-e.jpg')}
              />
            </div>

            <p className='mt-3'>
              Unlike enameled wires, 3 layers of insulation of patented design
              are extruded over the copper conductor. IEC60950 requires some
              Reinforced Insulation between the primary and secondary coils.
              Thus enamelled wire transformers can never omit the insulation
              tape or barrier tape: enamel coatings give poor electric
              insulation. In contrast, TEX-E can form primary and secondary
              coils without a need of barrier tape or interlayer tape
              in-between. Each TEX-E wire is equipped with a three-fold
              insulation around the conductor, which has been authorized as
              Reinforced Insulation. As a result, the creepage distance can
              never be limited and no insulation barrier is needed, when
              specifying distances between termination pins and coils.
            </p>

            <div className='mt-3 text-center'>
              <img
                alt='Conventional vs. TEX-E'
                className='img-fluid'
                src={withPrefix('/img/conventional.jpg')}
              />
            </div>

            <p className='mt-3'>
              TEX-E has been in the market for more than 12 years. Today we have
              more than 2,500 TEX-E customers over the world, as a proof of the
              safe and steady performance of our wire products (TEX-E Wires).
              Their applications cover most switching transformers, information
              & telecommunications equipment, game machines, consumer goods,
              inverters and other similar devices.
            </p>

            <div className='mt-3 text-center'>
              <img
                alt='Examples'
                className='img-fluid'
                src={withPrefix('/img/examples.jpg')}
              />
            </div>

            <h3 className='mt-3'>
              Applied Commercial Devices &amp; Instruments
            </h3>

            <ul>
              <li>
                SMPS for units of Printer, Facsimile, Memory, Computer, Monitor,
                Inverter, Game Machine
              </li>

              <li>
                Battery Charger for assemblies of Digital Camera, Portable
                Telephone, 8mm VCR, AC Adapter, Personal Computer, DVD
              </li>

              <li>
                Operating Frequencies: Typically 85 kHz to 200kHz but level with
                a maximum of 500kHz
              </li>
            </ul>

            <h3>Features of TEX-E</h3>

            <ol>
              <li>
                Certified as Reinforced Insulation; the combination of any two
                layers can resist up to 3,000 ACV for one minute.
              </li>

              <li>
                Falling into Thermal Resistance A(105°C) to E(120°C). Soldered
                without any surface layer bared (stripped).
              </li>

              <li>
                Smoothly coiled by automatic coiling machines. Offering a wide
                size choice of 0.20mm (approx. #32 AWG) to 1.0mm (approx. #18
                AWG).
              </li>
            </ol>

            <h3>Dimensions</h3>

            <p>
              A standard type wire in TEX-E series, is made up of a conductor
              and three insulation layers of modified polyester and thermal
              resistant resin. This was designed and developed to aid
              solderability and eliminating the need for stripping.
            </p>

            <h3>Color</h3>

            <p>
              The jacket insulation on TEX-E and TEX-ELZ wires are manufactured
              in yellow only. See the TEX-E Product Brochure for the sizes we
              carry and other technical information.
            </p>

            <h3>Stock</h3>
            <p>
              Wire Specialties currently stocks TEX-ELZ wires in 5 sizes:
              <ul>
                <li>7/0.10mm</li>
                <li>7/0.15mm</li>
                <li>7/0.20mm</li>
                <li>7/0.25mm</li>
                <li>7/0.30mm</li>
              </ul>
              Other sizes can be ordered and have a 3-month lead time. See the
              TEX-ELZ Product Brochure for more sizes and technical information.
            </p>
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
};
